import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

/**
 * Function that transforms a date like DD/MM/YYYY or MM-DD-YYYY to Month DD, YYYY
 * @param {string} date Date with format DD/MM/YYYY or MM-DD-YYYY
 */
export const useFormatDateToLongDate = (date: string) =>
  useI18n().date(new Date(date), {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
