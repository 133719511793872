<template>
  <div ref="reviewObserverTarget">
    <div
      class="flex flex-col flex-wrap items-start justify-between gap-32 md:flex-row"
      data-qa="user-comment"
    >
      <!-- Customer info -->
      <div class="flex items-start">
        <RevAvatar
          class="mr-12 shrink-0"
          :class="avatarBackground"
          :label="avatarLabel"
          size="medium"
          :thumbnail="authIconAvatar"
        />

        <div class="flex-1">
          <p class="body-1-bold">
            {{ userName }}
          </p>

          <p v-if="purchaseDate" class="text-static-default-low body-2">
            {{ purchaseDate }}
          </p>
        </div>
      </div>

      <!-- Handled with care block -->
      <div
        v-if="handledWithCare"
        class="bg-static-info-mid text-static-default-hi rounded-lg flex w-full grow p-16 md:order-2"
      >
        <IconHeadset class="mr-12 shrink-0" />

        <FormattedMessage :definition="translations.handledWithCare" tag="p">
          <template #text>
            <span class="body-1-bold">
              {{ i18n(translations.handledWithCareInnerText) }}
            </span>
          </template>
        </FormattedMessage>
      </div>

      <RevRating :score="averageRate" size="small" />
    </div>

    <ReviewText
      :comment
      data-test="main-review-comment"
      :force-expand="expandComment"
      :position
    />
    <!-- Translation -->
    <div
      v-if="isTranslatable"
      class="mt-24 flex flex-col flex-wrap items-start md:mt-32 md:flex-row md:items-center"
    >
      <div v-if="isTranslated" class="mb-8 mr-4 flex items-center md:mb-0">
        <IconSparkles class="mr-4" />

        {{ translatedFrom }}

        <span class="ml-4 hidden md:block"> - </span>
      </div>

      <RevLink @click="onToggleTranslation">
        {{ toggleTranslationLabel }}
      </RevLink>
    </div>

    <!-- Comment or update date -->
    <p class="text-static-default-low body-2 mt-32">
      {{ commentDate }}
    </p>

    <!-- Original review -->
    <div
      v-if="originalReview"
      class="border-static-default-low text-static-default-low mt-32 border-l-1 pl-24"
    >
      <p class="body-1-bold mb-8">{{ i18n(translations.originalReview) }}</p>

      <RevRating
        class="!text-static-default-low"
        :score="originalReview.averageRate"
        size="small"
      />

      <ReviewText
        :comment="originalReview.comment"
        data-test="original-review-comment"
        :position="position"
      />

      <p class="body-2 mt-32">
        {{
          i18n(translations.reviewDate, {
            country: i18n.country(originalReview.countryCode),
            date: useFormatDateToLongDate(originalReview.createdAt),
          })
        }}
      </p>
    </div>

    <!-- Product link -->
    <RevButtonBase
      v-if="displayedProduct.title"
      class="mt-32 block"
      :to="displayedProduct.link"
    >
      <RevTag :label="displayedProduct.title" variant="secondary" />
    </RevButtonBase>
  </div>
</template>

<script lang="ts" setup>
import { useAvatar, useRoute } from '#imports'
import { computed, inject, onBeforeUnmount, ref, toRef, watch } from 'vue'

import type { Review } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevAvatar } from '@ds/components/Avatar'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevLink } from '@ds/components/Link'
import { RevRating } from '@ds/components/Rating'
import { RevTag } from '@ds/components/Tag'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { useIntersectionObserver } from '@vueuse/core'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'
import { PRODUCT_INJECTION_KEY } from '~/scopes/product/symbols'

import ReviewText from './ReviewText.vue'
import translations from './UserComment.translations'
import { useFormatDateToLongDate } from './use-format-to-long-date'
import { useGetTranslatableComment } from './useGetTranslatableComment'

const props = withDefaults(
  defineProps<Review & { position: number; isHighlighted?: boolean }>(),
  { isHighlighted: false },
)

// Inject the product data provided by Product.vue
const injectedProduct = inject(PRODUCT_INJECTION_KEY, ref(null))

const i18n = useI18n()
const route = useRoute()
const { trackClick, trackReviewImpression } = useTracking()
const {
  market: { countryCode: currentCountryCode },
} = useMarketplace()
const {
  label: avatarLabel,
  thumbnail: authIconAvatar,
  backgroundColor: avatarBackground,
} = useAvatar(toRef(props.customer, 'firstName'))
const {
  comment,
  isTranslatable,
  isTranslated,
  toggleTranslation,
  toggleTranslationLabel,
  translatedFrom,
} = useGetTranslatableComment(props)

const reviewObserverTarget = ref<null | HTMLElement>(null)
const hasTriggeredImpression = ref(false)
const expandComment = ref(false)

const displayedProduct = injectedProduct?.value
  ? { ...injectedProduct.value, title: injectedProduct.value.rawTitle }
  : props.product

const userName = computed(() => {
  if (props.customer.firstName && props.customer.lastName) {
    return `${props.customer.firstName} ${props.customer.lastName}`
  }

  return (
    props.customer.firstName ??
    props.customer.lastName ??
    i18n(translations.anonymousUser)
  )
})

const commentDate = computed(() => {
  return props.handledWithCare
    ? i18n(translations.reviewUpdateDate, {
        country: i18n.country(props.countryCode),
        date: useFormatDateToLongDate(props.createdAt),
      })
    : i18n(translations.reviewDate, {
        country: i18n.country(props.countryCode),
        date: useFormatDateToLongDate(props.createdAt),
      })
})

const purchaseDate = computed(() =>
  props.product.purchasedAt
    ? i18n(translations.purchaseDate, {
        date: useFormatDateToLongDate(props.product.purchasedAt),
      })
    : '',
)

const isReviewFromCurrentCountry = computed(
  () => props.countryCode === currentCountryCode,
)

async function onToggleTranslation() {
  trackClick({
    name: isTranslated.value ? 'see_original' : 'translate',
    zone: `review_position_${props.position}`,
    page_type: route.name as string,
  })
  await toggleTranslation()

  if (isTranslated.value) {
    expandComment.value = true
  }
}

const { stop } = useIntersectionObserver(
  reviewObserverTarget,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      const routeName = route.name?.toString() || ''

      const { position, averageRate, countryCode, product } = props
      trackReviewImpression({
        ...product,
        averageRate,
        countryCode,
        isEdited: props.handledWithCare,
        isHighlighted: props.isHighlighted,
        isReviewFromCurrentCountry: isReviewFromCurrentCountry.value,
        isReviewTranslated: isTranslated.value,
        pageType: TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName],
        reviewPosition: position,
      })

      hasTriggeredImpression.value = true
    }
  },
)

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
