import { useAsyncData, useRoute } from '#imports'
import { computed, ref, watch } from 'vue'
import type { RouteLocation } from 'vue-router'

import type { HttpEndpoint } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { ROUTES } from '../../route-names'

import { useRequests } from './useRequests'
import translations from './useReviewsTitle.translations'

type Title = Record<string, string>

// TODO Adapt to dedicated service signature once it has been created
function useFetchTitle(route: RouteLocation) {
  const requests = useRequests()
  const routeName = route.name?.toString() ?? ''

  if (!routeName || !requests || !('getTitle' in requests))
    return { data: ref(''), error: ref() }

  const requestOptionsByRoute = {
    [ROUTES.PAGES.PRODUCT]: {
      pathParams: { productId: route.params.uuid },
    },
    [ROUTES.PAGES.LANDING]: {
      queryParams: { id: route.params.uuid },
    },
    [ROUTES.PAGES.MERCHANT]: {
      pathParams: { id: route.params.uuid },
    },
  }

  const responseHandlerByRoute = {
    [ROUTES.PAGES.PRODUCT]: {
      transform: ({ title }: Title) => title,
    },
    [ROUTES.PAGES.LANDING]: {
      transform: ({ displayedTitle }: Title) => displayedTitle,
    },
    [ROUTES.PAGES.MERCHANT]: {
      transform: ({ company }: Title) => company,
    },
  }

  return useAsyncData(
    'reviews-title',
    () =>
      $httpFetch(
        requests.getTitle as HttpEndpoint<Title>,
        requestOptionsByRoute[routeName] || {},
      ),
    responseHandlerByRoute[routeName] || {},
  )
}

export function useReviewsTitle() {
  const route = useRoute()
  const logger = useLogger()
  const i18n = useI18n()

  const { data: shortTitle, error } = useFetchTitle(route)

  watch(
    error,
    () => {
      if (error.value instanceof Error) {
        logger.error(`[REVIEWS][${route.name?.toString()}]`, {
          error: error.value,
        })
      }
    },
    { immediate: true },
  )

  const mainTitle = computed(() => {
    if (typeof shortTitle.value !== 'string') {
      return i18n(translations.titlePlaceholder)
    }

    switch (route.name) {
      case ROUTES.PAGES.PRODUCT:
        return i18n(translations.titleProduct, { product: shortTitle.value })

      case ROUTES.PAGES.MERCHANT:
        return i18n(translations.titleMerchantProducts, {
          merchant: shortTitle.value,
        })

      case ROUTES.PAGES.LANDING:
        return i18n(translations.titleListingProduct, {
          listing: shortTitle.value,
        })

      case ROUTES.PAGES.ALL:
        return i18n(translations.titleAllProducts)

      default:
        return i18n(translations.titlePlaceholder)
    }
  })

  return {
    mainTitle,
    shortTitle,
  }
}
