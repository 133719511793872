import { useRuntimeConfig } from '#imports'
import { type MaybeRefOrGetter, computed, onMounted, ref, toValue } from 'vue'

import { Locale, reviewsAPI } from '@backmarket/http-api'
import type { Review } from '@backmarket/http-api/src/api-specs-reviews/types/review'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import translations from './UserComment.translations'

export function useGetTranslatableComment(review: MaybeRefOrGetter<Review>) {
  const i18n = useI18n()
  const locale = useI18nLocale()
  const logger = useLogger()

  const { openErrorToast } = useTheToast()
  const {
    market: { countryCode: currentCountryCode },
  } = useMarketplace()
  const { FF_REVIEW_TRANSLATION_AUTO } = useRuntimeConfig().public

  const translatedComment = ref('')
  const isTranslated = ref(false)
  const hasError = ref(false)

  const commentToDisplay = computed(() => {
    const areCommentsIdentical =
      toValue(review).originalReview?.comment === toValue(review).comment
    if (areCommentsIdentical) {
      return ''
    }

    return isTranslated.value
      ? translatedComment.value
      : toValue(review).comment
  })

  const isTranslatable = computed(
    () => toValue(review).countryCode !== currentCountryCode,
  )

  const language = computed(() => {
    if (!isTranslatable.value) {
      return locale
    }
    if (isTranslated.value) {
      return i18n.language(toValue(review).languageCode as Locale)
    }

    const lang = locale.split('-')[0] || locale

    return i18n.language(lang as Locale)
  })

  const toggleTranslationLabel = computed(() => {
    return isTranslated.value
      ? i18n(translations.seeOriginalText)
      : i18n(translations.translateTextTo, { lang: language.value })
  })

  const translatedFrom = computed(() =>
    i18n(translations.textTranslatedFrom, { lang: language.value }),
  )

  async function fetchTranslation(reviewId: string) {
    try {
      const translation = await $httpFetch(reviewsAPI.getReview, {
        pathParams: { reviewId },
        queryParams: { locale },
      })

      translatedComment.value = translation.comment
      isTranslated.value = true
      hasError.value = false
    } catch (error) {
      hasError.value = true
      logger.error(error as string)
    }
  }

  async function toggleTranslation() {
    if (isTranslated.value || translatedComment.value) {
      isTranslated.value = !isTranslated.value
    } else {
      await fetchTranslation(toValue(review).id)

      if (hasError.value) {
        openErrorToast({
          title: i18n(translations.errorToastTitle),
          content: i18n(translations.errorToastMessage),
        })
      }
    }
  }

  onMounted(() => {
    const autoTranslateComment =
      isTranslatable.value && FF_REVIEW_TRANSLATION_AUTO
    if (autoTranslateComment) {
      void fetchTranslation(toValue(review).id)
    }
  })

  return {
    comment: commentToDisplay,
    isTranslatable,
    isTranslated,
    toggleTranslation,
    toggleTranslationLabel,
    translatedFrom,
  }
}
